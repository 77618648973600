import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";

import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert"


// availity-reactstrap-validation
import {
  AvForm,
  AvField,AvRadioGroup,AvRadio
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withRouter, Link } from "react-router-dom";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData } from "../../store/functions";

class pageAddEdit_GlobalOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
    };
    this.obj = this;
    this.formTitle = "Global Options";
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Add " + this.formTitle;
    this.API_URL_SAVE = API_WEB_URLS.GLOBALOPTIONS + "/0/token";
    this.pushFormName = "/GlobalOptions";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/GlobalOptions";
  }
  componentDidMount() {
    
    const obj = JSON.parse(localStorage.getItem("authUser"));
      Fn_DisplayData(this.obj, obj.uid, this.API_URL + "/F_CustomerMaster");
      Fn_FillListData(this.obj, "WeekMaster", API_WEB_URLS.MASTER + "/0/token/WeekMaster/Id/0");
    
  }
  btnSave_onClick(event, formData) {
   




    const obj = JSON.parse(localStorage.getItem("authUser"));

    
      Fn_AddEditData(
        this.obj,
        { arguList: 
          { id: 0,
            MinHoursDiffForNextIn: formData.MinHoursDiffForNextIn ,
            ShiftStart : formData.ShiftStart ,
            ShiftEnd : formData.ShiftEnd ,
            LegalInTime : formData.LegalInTime ,
            LegalOutTime : formData.LegalOutTime ,
            LegalShiftStart : formData.LegalShiftStart ,
            LegalShiftEnd : formData.LegalShiftEnd ,
            MaxDaysForLeave : formData.MaxDaysForLeave ,
            F_CustomerMaster : obj.uid,
            maxWorkingHours : formData.MaxWorkingHours ,
            minWorkingHours : formData.MinWorkingHours ,
            maxWorkingHours4HF : formData.MaxWorkingHours4HF ,
            minWorkingHours4HF : formData.MinWorkingHours4HF ,
            overtimeGracePeriod : formData.OvertimeGracePeriod ,
            f_WeeklyHoliday : formData.F_WeeklyHoliday ,
            maxAllowedLeavesPerMonth : formData.MaxAllowedLeavesPerMonth 
              } },
        this.API_URL_SAVE,
        this.pushFormName
      );
  



  }

  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
                    <div className="wizard clearfix">
                     
                      <div className="content clearfix">
                        <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                         
                                <Row>
                                  <Col lg="6">
                                  <Card>
                                    <CardBody>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">MinHoursDiffForNextIn</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="MinHoursDiffForNextIn" label="" value={this.state.formData.MinHoursDiffForNextIn === null ? ''   : this.state.formData.MinHoursDiffForNextIn} placeholder="Enter MinHoursDiffForNextIn "  validate={{ required: { value: true } }} type="number" className="form-control" />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="middleName" className="col-form-label">ShiftStart</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="ShiftStart" label="" value={this.state.formData.ShiftStart === null ? ''   : this.state.formData.ShiftStart} placeholder="Enter  ShiftStart" errorMessage="Enter UserName" validate={{ required: { value: true } }}  type="time" className="form-control" />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="lastName" className="col-form-label">ShiftEnd</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="ShiftEnd" label="" value={this.state.formData.ShiftEnd === null ? ''   : this.state.formData.ShiftEnd} placeholder="Enter ShiftEnd" errorMessage="Enter ShiftEnd" validate={{ required: { value: true } }} type="time" className="form-control" />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">LegalInTime</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="LegalInTime" label="" value={this.state.formData.LegalInTime === null ? ''   : this.state.formData.LegalInTime} placeholder="Enter LegalInTime" errorMessage="Enter ShiftEnd" validate={{ required: { value: true } }} type="time" className="form-control" />
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">LegalOutTime</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="LegalOutTime" label="" value={this.state.formData.LegalOutTime === null ? ''   : this.state.formData.LegalOutTime} placeholder="Enter LegalOutTime" errorMessage="Enter LegalOutTime" validate={{ required: { value: true } }} type="time" className="form-control" />
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">LegalShiftStart</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="LegalShiftStart" label="" value={this.state.formData.LegalShiftStart === null ? ''   : this.state.formData.LegalShiftStart} placeholder="Enter LegalShiftStart" errorMessage="Enter LegalShiftStart" validate={{ required: { value: true } }} type="time" className="form-control" />
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">LegalShiftEnd</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="LegalShiftEnd" label="" value={this.state.formData.LegalShiftEnd === null ? ''   : this.state.formData.LegalShiftEnd} placeholder="Enter LegalShiftEnd" errorMessage="Enter LegalShiftEnd" validate={{ required: { value: true } }} type="time" className="form-control" />
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">MaxDaysForLeave</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="MaxDaysForLeave" label="" value={this.state.formData.MaxDaysForLeave === null ? ''   : this.state.formData.MaxDaysForLeave} placeholder="Enter MaxDaysForLeave" errorMessage="Enter ShiftEnd" validate={{ required: { value: true } }} type="number" className="form-control" />
                                        </Col>
                                      </Row>



                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">MaxWorkingHours(Full Day)</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="MaxWorkingHours" label="" value={this.state.formData.MaxWorkingHours === null ? ''   : this.state.formData.MaxWorkingHours} placeholder="Enter MaxWorkingHours" errorMessage="Enter MaxWorkingHours" validate={{ required: { value: true } }} type="number" className="form-control" />
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">MinWorkingHours(Full Day)</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="MinWorkingHours" label="" value={this.state.formData.MinWorkingHours === null ? ''   : this.state.formData.MinWorkingHours} placeholder="Enter MinWorkingHours" errorMessage="Enter MinWorkingHours" validate={{ required: { value: true } }} type="number" className="form-control" />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">MaxWorkingHours (Half Day)</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="MaxWorkingHours4HF" label="" value={this.state.formData.MaxWorkingHours4HF === null ? ''   : this.state.formData.MaxWorkingHours4HF} placeholder="Enter MaxWorkingHours4HF" errorMessage="Enter MaxWorkingHours4HF" validate={{ required: { value: true } }} type="number" className="form-control" />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">MinWorkingHours (Half Day)</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="MinWorkingHours4HF" label="" value={this.state.formData.MinWorkingHours4HF === null ? ''   : this.state.formData.MinWorkingHours4HF} placeholder="Enter MinWorkingHours4HF" errorMessage="Enter MinWorkingHours4HF" validate={{ required: { value: true } }} type="number" className="form-control" />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">Overtime Grace Period (in Minutes)</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="OvertimeGracePeriod" label="" value={this.state.formData.OvertimeGracePeriod === null ? ''   : this.state.formData.OvertimeGracePeriod} placeholder="Enter OvertimeGracePeriod" errorMessage="Enter OvertimeGracePeriod" validate={{ required: { value: true } }} type="number" className="form-control" />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">Weekly Holiday (default)</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField onChange={this.checkfemale} name="F_WeeklyHoliday" label="" value={this.state.formData.F_WeeklyHoliday === null ? '-1'   : this.state.formData.F_WeeklyHoliday}  type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.WeekMaster
                                              ? this.state.WeekMaster.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField> 


                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="maidenName" className="col-form-label">MaxLeavesAllowedPerMonth</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="MaxAllowedLeavesPerMonth" label="" value={this.state.formData.MaxAllowedLeavesPerMonth === null ? ''   : this.state.formData.MaxAllowedLeavesPerMonth} placeholder="Enter MaxLeavesAllowedPerMonth" errorMessage="Enter MaxAllowedLeavesPerMonth" validate={{ required: { value: true } }} type="number" className="form-control" />
                                        </Col>
                                      </Row>

                                    
                                    </CardBody>
                                  </Card>
                                  </Col>
                                 
                                </Row>
                                <Row>
                                </Row>
                           
                                <div className="d-flex flex-wrap gap-2">
                          <Button
                          type="submit"
                          color="primary"
                          className="mr-1 waves-effect waves-light"
                        >
                          Update
                        </Button>

                        <Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.btnCancel_onClick}
                        >
                          Cancel
                        </Button>


                        {this.state.success_msg ? (
                      <SweetAlert
                        title="Customer Saved Successfully!"
                        success
                       
                        confirmBtnBsStyle="success"
                      
                        onConfirm={this.syno}
                        
                      >
                        You clicked the button!
                      </SweetAlert>
                    ) : null}
                          </div>
                        </AvForm>
                      </div>
                      
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_GlobalOptions);
