import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store


import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_GetReport,
  Fn_AddEditData,
  Fn_ChangeStateValue
} from "../../store/functions";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal
} from "reactstrap";

import {
  AvForm,
  AvField,AvRadioGroup,AvRadio
} from "availity-reactstrap-validation";

import { compose } from "recompose";
import PropTypes from 'prop-types'
import { container } from "../../store/Containers/cntCommon";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react"

import { initializeApp } from "firebase/app";
import { getDatabase, ref, get } from "firebase/database";


  
const firebaseConfig = {
  apiKey: "AIzaSyB4xxmODUWwphCmkSnM0R56xUzn5EM6ZhQ",
  authDomain: "vc-app-9beac.firebaseapp.com",
  databaseURL: "https://vc-app-9beac-default-rtdb.firebaseio.com",
  projectId: "vc-app-9beac",
  storageBucket: "vc-app-9beac.appspot.com",
  messagingSenderId: "563535596504",
  appId: "1:563535596504:web:ff209154658c12ed64c0ad",
  measurementId: "G-1V6MJ7DQ1S"
};

const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);


const LoadingContainer = () => <div>Loading...</div>



class pageList_AppAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      formData : {},
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      company : [],
      InTimeN : '00:00',
      OutTimeN : '00:00',
      modal_center : false
    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "App Attendance";
    this.modalTitle = "App Attendance";
    this.rtPage_Add = "add-customermaster";
    this.rtPage_Edit = "edit-customermaster";
    this.API_URL = "GetAppAtt/0/token";
    this.API_URL_COMPANY = API_WEB_URLS.MASTER + "/0/token/EmployeeMasterDrop/Id/0";

    //Event Binding
    this.btnAdd_onClick = this.btnAdd_onClick.bind(this);
    this.btnEdit_onClick = this.btnEdit_onClick.bind(this);
    this.btnDelete_onClick = this.btnDelete_onClick.bind(this);
    this.btnSave_onClick =  this.btnSave_onClick.bind(this);
    this.viewimg  = this.viewimg.bind(this);
    this.tog_center = this.tog_center.bind(this);
   

  }



  tog_center =  (lat, long, date , time )=> {
    this.setState(prevState => ({
      modal_center: !prevState.modal_center,
      lat : lat,
      lng : long,
      date : date,
      time : time
    }))
    this.removeBodyCss()
  }


  componentDidMount() {
    const { id } = this.props.match.params;
    const obj = JSON.parse(localStorage.getItem("authUser"));
    Fn_FillListData(this.obj, "employee",  this.API_URL_COMPANY);
  }

 

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  btnSave_onClick (event, formData)  {

    let vformData = new FormData();
    vformData.append("FromDate", formData.FromDate);
    vformData.append("ToDate", formData.FromDate);
    vformData.append("F_EmployeeMaster", formData.EmployeeId);

    Fn_GetReport(this.obj, { arguList: { id: 0, formData: vformData } }, this.API_URL, "gridData", true);


  }


 


  btnAdd_onClick(event, values) {
    this.props.history.push(`${this.rtPage_Add}`);
  }
  btnEdit_onClick(formData) {
    this.props.history.push(`${this.rtPage_Edit}/${formData.Id}`, {});
  }
  btnDelete_onClick(formData) {
    Fn_DeleteData(this.obj, formData.Id, this.API_URL, this.API_URL + "/Id/0");
  }
  renderGridHeader() {
    return (
      <>
        <th>Date </th>
        <th>Time </th>
        <th>Type</th>
        <th>View Map</th>
        <th>View Image</th>
     
      </>
    );
  }

viewimg () {

}

  renderGridBody(formData) {
    return (
      <>
        <td>{formData.DatePart}</td>
        <td>{formData.TimePart}</td>
        <td>{formData.Type ? 'In' : 'out'}</td>
        <td>
          
        <Button
                          type="button"
                          color="primary"
                          onClick={() => this.tog_center(formData.Latitude, formData.Longitude, formData.DatePart, formData.TimePart )}
                          className="mr-1 waves-effect waves-light"
                        >
                          View
                        </Button>
          
          </td>

        <td> <a className="mr-1 waves-effect waves-light" href={API_WEB_URLS.IMAGEURL+formData.IMGURL} target="_blank" rel="noreferrer">View</a></td>
      </>
    );
  }


 

  renderModalBody(selectedFormData) {

    
    return (
      <>
          


        
      </>
    );
  }
  render() {
    return (




     <>

<React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                   
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                            
                            <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                         
                                <Row>
                                  <Col lg="9">
                                  {/* <Card>
                                    <CardBody> */}
                                    <Row>
                                    <Col sm="1" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label"> Employee</label>
                                        </Col>
                                        <Col sm="3">
                                        <AvField name="EmployeeId"  label=""  value={this.state.formData.EmployeeId === null ? '-1'   : this.state.formData.EmployeeId} errorMessage="Select EmployeeId " validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.employee
                                              ? this.state.employee.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>

                                        <Col sm="2" className="mb-3">
                                          <label htmlFor="DateofBirth" className="col-form-label">From Date</label>
                                        </Col>
                                        <Col sm="3" className="mb-3">
                                        <AvField name="FromDate" label=""  onChange={(e) => Fn_ChangeStateValue(this.obj, 'AttDate', e.target.value)} value={this.state.formData.FromDate} placeholder="From Date" errorMessage="Select Date " validate={{ required: { value: true } }} type="date"  className="form-control" />
                                        </Col>

                                        <Col sm="3" className="mb-3">
                                        <Button
                          type="submit"
                          color="primary"
                        
                          className="mr-1 waves-effect waves-light"
                        >
                          View
                        </Button>
                                          </Col>
                                      
                                    
                                      </Row>

                                     
                                   

                       
                                      
                         {/* </CardBody>
                         </Card> */}
                         </Col>
                         </Row>
                        

                         
                         </AvForm>

                         </div> 



                         </div>

                        
                       
                          </CardBody>
                         </Card>


                         
                         </Col>
                         </Row>



                         
                         </Container>
                         </div>
                         </React.Fragment>
                         <Modal
                        isOpen={this.state.modal_center}
                        toggle={this.tog_center}
                        centered={true}
                      >
                        <div className="modal-header">
                          <h5 className="modal-title mt-0">Map</h5>
                          <button
                            type="button"
                            onClick={() =>
                              this.setState({ modal_center: false })
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                        <Col lg={6}>
                <Card>
                  <CardBody>
                    
                    <div
                      id="gmaps-markers"
                      className="gmaps"
                      style={{ position: "relative" }}
                    >
                       <Map
                          google={this.props.google}
                          style={{ width: "100%", height: "100%" }}
                          zoom={14}
                          initialCenter={{ lat: this.state.lat, lng: this.state.long }} // Set initial center here
                        >
                          <Marker
                            title={this.state.date + 'T' + this.state.time}
                            name={this.state.date + 'T' + this.state.time}
                            label={this.state.date + 'T' + this.state.time}
                            position={{ lat: this.state.lat, lng: this.state.long }}
                          >
                          {/* <Marker name={"Dolores park"} /> */}
                          <InfoWindow
                        
                          >
                            <div>
                              <h1>{this.state.Name}</h1>
                            </div>
                          </InfoWindow>
                          </Marker>
                        </Map>
                    </div>
                  </CardBody>
                </Card>
              </Col>
                        </div>
                      </Modal>


      <RCDisplayPage
        //page header paramaters
        Isbreadcrumb = {false}
        breadCrumbTitle={this.breadCrumbTitle}
        breadcrumbItem={this.breadCrumbItem}
        obj={this.obj}
        //column paramaters
        isSearchBox={false}
        isSNo={true}
        isCheckBox={true}
        isViewDetails={false}
        InTimeN = {this.state.InTimeN}
        OutTimeN={this.state.OutTimeN}
        //grid paramaters
        gridData={this.state.gridData}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        removeBodyCss={this.removeBodyCss}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        updateatt={this.updateatt}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        togglemodal2={togglemodal}
        tog_center={this.tog_center}
      
        edit={this.edit}
        //user rights
        isAdd={false}
        isEdit={false}
        isDelete={false}
      ></RCDisplayPage>
  </>
    );
  }
}





pageList_AppAttendance.propTypes = {
  google: PropTypes.object
}

export default compose(container)(
  GoogleApiWrapper({
    apiKey: "AIzaSyBZmCAe7jFv7GKMxqwCV-VXa8XuwrlB8EE",
    LoadingContainer: LoadingContainer,
    v: "3",
  })(pageList_AppAttendance)
)
