import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip
} from "reactstrap";
import Switch from "react-switch"
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert"
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";


// Editable
import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory from "react-bootstrap-table2-editor"
// availity-reactstrap-validation
import {
  AvForm,
  AvField,AvRadioGroup,AvRadio
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData, Fn_ChangeStateValue, Fn_DeleteData, Fn_GetReport } from "../../store/functions";
import AddDeleteTableAtt from "pages/Transaction/AddDeleteTableAtt";
import {OutTable, ExcelRenderer} from 'react-excel-renderer';

export const DateString = (dd) =>{

let d1 =  dd.replace('-', '');
let d2 = d1.replace('-', '');

return d2;

}

class pageAddEdit_AttbyPayroll  extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
       activeTab: 1,
       passedSteps: [1],
       BrowseImage1 : '',
       BrowseImage2 : '',
       UserImage : '',
       Signature : '',
      //dropdowns
      membershipType: [],
      maritalStatus: [],
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
      memberid : 0,
      name : "",
      email : "",
      aid : 0,
      fo3 : false,
      Gender : 0,
      MaritalStatus : 0,
      success_msg : false,
      F_MISScheme : 0,
      IsOvertime : false,
      save : false , cancel:false , add:true,
      WorkingDays : [{
        WorkingDays : 0
      }],
     
      //dropdowns
      
     
      EmpData: [{
        Id   :  '', Name : '', PaidDays :  '', EmpId :  '' , Month :  '', Year  :  '', IsDone :  '', FatherName :  '',
        Department : '', Leaves : '', WorkingHolidays : ''
      }]



    };
    this.obj = this;
    this.formTitle = "Edit Shift";
    this.breadCrumbTitle = "Memberships";
    this.breadCrumbItem = " " + this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/EmployeeMaster";
    this.API_URL_SAVE = API_WEB_URLS.AddEdit + "/0/token";
    this.pushFormName = "/#";
    this.rtPage_Print = "/#";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.btnGoBack_onClick = this.btnGoBack_onClick.bind(this);
    
    this.editrow   = this.editrow.bind(this);
    this.viewData  = this.viewData.bind(this);
    this.fileHandler  = this.fileHandler.bind(this);
    
  }

  componentDidMount() {
    var F_CompanyMaster  =  localStorage.getItem("F_CompanyMaster");
    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({ name: obj.username, email: obj.email, aid: obj.uid , role:obj.role, F_CompanyMaster : F_CompanyMaster });
       
     

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }   
  }




  viewData () {


    this.setState({loading: true});
    const obj = JSON.parse(localStorage.getItem("F_CompanyMaster"));
    let vformData = new FormData();
                    vformData.append("Year", this.state.YearId);
                    vformData.append("Month", this.state.MonthId);
                    Fn_GetReport(
                      this.obj,
                      { arguList: { id: 0, formData: vformData } },
                      "GetEmpForPayrollAtt/0/token",
                      "EmpData",
                      true
                    );


                    Fn_GetReport(
                      this.obj,
                      { arguList: { id: 0, formData: vformData } },
                      "GetWorkingDays/0/token",
                      "WorkingDays",
                      true
                    );

  }



btnSave_onClick(event, formData) {
let MonthDays  = this.state.WorkingDays[0].DaysInMonths;
let WorkingHolidays  =  this.state.WorkingDays[0].WeeklyHolidays;

var isOkay  =  0;

    for (let i= 0; i< this.state.EmpData.length;  i++){
      const item = this.state.EmpData[i];
        const leaveDays = item.Leaves ? item.Leaves.split(',').length : 0;

      const totaldays  =  Number(leaveDays)+Number(item.PaidDays)+Number(item.WorkingHolidays);

      if (totaldays > MonthDays){
        alert('Month days greater ')
        isOkay = 0
        break;
      }
      else if (item.WorkingHolidays > WorkingHolidays){
        alert('Working Holidays are greater than month having sunday')
        isOkay = 0
        break;
      }
      else {
        isOkay = 1
      }
      
    }

    if (isOkay == 1){
 console.log(this.state.EmpData);

 let vformData2 = new FormData();
 vformData2.append("Data", JSON.stringify(this.state.EmpData));
  Fn_AddEditData(this.obj, { arguList: { id: 0, formData: vformData2 } }, "InsertRandomAtt/0/token", "#", true);
  this.setState({EmpData:[{}]})

    }

}

fileHandler = () => {

  this.setState({EmpData : [{}]})
  let fileObj = this.state.File;

  //just pass the fileObj as parameter
  ExcelRenderer(fileObj, (err, resp) => {
    if(err){
      console.log(err);            
    }
    else{

    const columns = resp.rows[0];
  const data = resp.rows.slice(1);

  const transformedData = data.map((row) => {
    const rowData = {};
    columns.forEach((column, index) => {
      rowData[column] = row[index];
    });
    return rowData;
  });


  const arr2 = transformedData.map((item, index) => ({
    Id: index + 1,
    Name: item.EmployeeName,
    PaidDays: item.WorkingDays,
    EmpId: item.EmployeeNo,
    Month: item.MonthNumber,
    Year: item.Year,
    IsDone: 0,
    FatherName: item.FatherName,
    Department : item.Department,
    Leaves : item.LeaveDates,
    WorkingHolidays : item.WorkingHolidays
  }));
     
  let vformData = new FormData();
  vformData.append("Year", arr2[0].Year);
  vformData.append("Month", arr2[0].Month);
  Fn_GetReport(
    this.obj,
    { arguList: { id: 0, formData: vformData } },
    "GetWorkingDays/0/token",
    "WorkingDays",
    true
  );

  this.setState({EmpData:arr2, YearId : arr2[0].Year , MonthId: arr2[0].Month});
    

  }
  });   

  
  
  

}






btnGoBack_onClick = event => {
  event.preventDefault();
  //this.props.history.goBack();
  this.props.history.push(this.pushFormName);
};



  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };





   


    editrow = (index, evnt) =>{


      
      const { name, value } = evnt.target;
  

      if ( name == 'PaidDays' && value>this.state.WorkingDays[0].WorkingDays){
        alert('working days cant be more than month days');
      }
      else{
        const rowsInput = [...this.state.EmpData];
      rowsInput[index][name] = value;
  
      this.setState({EmpData: rowsInput});
        
      }
  
  }
  

    
  render() {   
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />

<Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                    <Row>

<Col sm="1" className="mb-3">
                        <label htmlFor="firstName" className="col-form-label"> Select Year</label>
                      </Col>
                      <Col sm="2">
                      <select name="YearId"  label="" onChange={(e) => this.setState({YearId : e.target.value})}  value={this.state.YearId} errorMessage="Select YearId " validate={{ required: { value: true } }} type="select" className="form-select" >
                          <option value={-1} defaultValue label={"Select"} />
                          <option value={2023} defaultValue label={"2023"} />
                          <option value={2024} defaultValue label={"2024"} />
                          <option value={2025} defaultValue label={"2025"} />
                        </select>
                      </Col>


                  <Col sm="1" className="mb-3">
                        <label htmlFor="firstName" className="col-form-label"> Select Month</label>
                      </Col>
                      <Col sm="2">
                      <select name="MonthId"  label="" onChange={(e) => this.setState({MonthId : e.target.value})}  value={this.state.MonthId} errorMessage="Select MonthId " validate={{ required: { value: true } }} type="select" className="form-select" >
                      <option value={-1} defaultValue label={"Select"} />
                                            <option value={1} defaultValue label={"Jan"} />
                                            <option value={2} defaultValue label={"Feb"} />
                                            <option value={3} defaultValue label={"Mar"} />
                                            <option value={4} defaultValue label={"Apr"} />
                                            <option value={5} defaultValue label={"May"} />
                                            <option value={6} defaultValue label={"Jun"} />
                                            <option value={7} defaultValue label={"Jul"} />
                                            <option value={8} defaultValue label={"Aug"} />
                                            <option value={9} defaultValue label={"Sep"} />
                                            <option value={10} defaultValue label={"Oct"} />
                                            <option value={11} defaultValue label={"Nov"} />
                                            <option value={12} defaultValue label={"Dec"} />
                        </select>
                      </Col>


                      
                      <Col sm="2" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label"> Choose File</label>
                                        </Col>
                                        <Col sm="4" className="mb-3">
                                        <input name="file"  label="" value={this.state.formData.file} onChange={(event)=> Fn_ChangeStateValue(this.obj, "File", event.target.files[0])}   type="file"  className="form-control" />
                                        </Col>
               
                      <Col sm="3">
                      <Button
        type="button"
        onClick={this.viewData}
        color="primary"
        className="mr-1 waves-effect waves-light"
      >
        View
      </Button>
<></>

      <Button
        type="button"
        onClick={this.fileHandler}
        color="primary"
        className="mr-1 waves-effect waves-light"
      >
        View Excel Data
      </Button>

      <></>

                      </Col>


                      </Row>



                    </CardBody>
                  </Card>
                </Col>
              </Row>

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
                    <div className="wizard clearfix">
                     <div className="content clearfix">
                       <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick} >
                          <TabContent activeTab={this.state.activeTab} className="body">
                            <TabPane tabId={1}>
                                <Row>
                                  <Card>
                  <CardBody>
                  <AddDeleteTableAtt data={this.state.EmpData}   edit={this.editrow} />
                  </CardBody>
                </Card>
                                     
                                 </Row>    
                                                                                                                                                                                                         
                            </TabPane>                                                  
                          </TabContent>
                          <div>

                       

                          <Button
                          type="submit"
                          color="primary"
                          className="mr-1 waves-effect waves-light"
                        >
                          Save
                        </Button>
                        
                        &nbsp; 

                        <Button
                          type="button"
                          color="primary"
                          className="mr-1 waves-effect waves-light"
                          onClick={this.btnGoBack_onClick}
                        >
                          Go Back
                        </Button>
                        
                          </div>
                        </AvForm>
                      </div>                    
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_AttbyPayroll);