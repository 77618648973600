import React from "react";
import { Redirect } from "react-router-dom";
// User profile
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ChangePassword from "../pages/Authentication/ChangePassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
// Masters

import pageList_CustomerMaster from "../pages/Masters/pageList_CustomerMaster";
import pageAddEdit_CustomerMaster from "pages/Masters/pageAddEdit_CustomerMaster";
import pageList_CompanyMaster from "pages/Masters/pageList_CompanyMaster";
import pageAddEdit_CompanyMaster from "pages/Masters/pageAddEdit_CompanyMaster";
import pageAddEdit_ImportFile from "pages/Masters/pageAddEdit_ImportFile";
import pageList_DayWiseAtt from "pages/Masters/pageList_DayWiseAtt";
import pageAddEdit_EmployeeMaster from "pages/Masters/pageAddEdit_EmployeeMaster";
import pageList_AuditAtt from "pages/Masters/pageList_AuditAtt";
import pageAddEdit_GlobalOptions from "pages/Masters/pageAddEdit_GlobalOptions";
import pageList_Test from "pages/Masters/pageList_Test";
import pageList_ShiftMaster from "pages/Masters/pageList_ShiftMaster";
import pageAddEdit_ShiftMaster from "pages/Masters/pageAddEdit_ShiftMaster";
import pageAddEdit_EditShift from "pages/Masters/pageAddEdit_EditShift";
import pageList_EmployeeMaster from "pages/Masters/pageList_EmployeeMaster";
import ChangeAtt from "pages/Masters/ChangeAtt";
import pageList_AppAttendance from "pages/Masters/pageList_AppAttendance";
import pageList_Holiday from "pages/Masters/pageList_Holiday";
import pageAddEdit_Holiday from "pages/Masters/pageAddEdit_Holiday";
import Dashboard2 from 'pages/Dashboard/index2';
import pageList_EmployeeAttSheet from "pages/Masters/pageList_EmployeeAttSheet";
import pageList_EmployeeAttSummary from "pages/Masters/pageList_EmployeeAttSummary";
import pageList_EmployeeAttOTSummary from "pages/Masters/pageList_EmployeeAttOTSummary";
import pageReport_ManualMustorRoll from "pages/Masters/pageReport_ManualMustorRoll";
import pageList_MonthlyStatusReport from "pages/Masters/pageList_MonthlyStatusReport";
import pageAddEdit_AttByPayroll from "pages/Masters/pageAddEdit_AttByPayroll";


const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  //profile
  { path: "/profile", component: UserProfile },
  { path: "/changepassword", component: ChangePassword },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

  //Masters

  { path: "/masters-shiftmaster", component: pageList_ShiftMaster },

  { path: "/manualmustorroll", component: pageReport_ManualMustorRoll },

  { path: "/attbypayroll", component: pageAddEdit_AttByPayroll },

  { path: "/monthlystatusreport", component: pageList_MonthlyStatusReport },


  { path: "/employeeattsheet", component: pageList_EmployeeAttSheet },
  { path: "/employeeattsummary", component: pageList_EmployeeAttSummary },
  { path: "/employeeotsummary", component: pageList_EmployeeAttOTSummary },

  { path: "/dashboard2", component: Dashboard2 },

  { path: "/add-shiftmaster", component: pageAddEdit_ShiftMaster },
  { path: "/add-holiday", component: pageAddEdit_Holiday },
  { path: "/edit-holiday/:id", component: pageAddEdit_Holiday },

  { path: "/editemp-shiftmaster", component: pageAddEdit_EditShift },
  { path: "/edit-shiftmaster/:id", component: pageAddEdit_ShiftMaster },
  { path: "/masters-customermaster", component: pageList_CustomerMaster },
  { path: "/add-customermaster", component: pageAddEdit_CustomerMaster },


  
  { path: "/audit-attendance", component: pageList_AuditAtt },

  { path: "/holiday", component: pageList_Holiday },

  { path: "/appattendance", component: pageList_AppAttendance },

  { path: "/changeatt", component: ChangeAtt },

  
  { path: "/edit-customermaster/:id", component: pageAddEdit_CustomerMaster },



  { path: "/add-employeemaster", component: pageAddEdit_EmployeeMaster },
  { path: "/edit-employeemaster/:id", component: pageAddEdit_EmployeeMaster },
  { path: "/masters-employeemaster", component: pageList_EmployeeMaster },


  //

  { path: "/masters-companymaster", component: pageList_CompanyMaster },


  { path: "/listtest", component: pageList_Test },


  
  { path: "/add-companymaster", component: pageAddEdit_CompanyMaster },
  { path: "/edit-companymaster/:id", component: pageAddEdit_CompanyMaster },

  //

  { path: "/add-importfile", component: pageAddEdit_ImportFile },


//Global Options
  { path: "/GlobalOptions", component: pageAddEdit_GlobalOptions },


  //Reports
  { path: "/daywiseatt", component: pageList_DayWiseAtt },


];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes };
