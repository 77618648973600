import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Input,
  Button,
  Card,
  CardBody,
  Table,
  Label,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress
} from "reactstrap";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import Breadcrumbs from "components/Common/Breadcrumb";
import { Link } from "react-router-dom";

class RCDisplayPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      itemsPerPage: 10,
    };
  }

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
    this.props.handlePageChange(pageNumber);
  };

  render() {
    const { itemsPerPage, currentPage } = this.state;
    const { gridData, filteredData } = this.props;
    const dataToPaginate = filteredData || gridData;

    if (dataToPaginate) {
      const totalPages = Math.ceil(dataToPaginate.length / itemsPerPage);
      const visiblePages = 5; // Number of pages to show in the pagination
      const halfVisiblePages = Math.floor(visiblePages / 2);

      let startPage, endPage;

      if (totalPages <= visiblePages) {
        startPage = 1;
        endPage = totalPages;
      } else {
        if (currentPage <= halfVisiblePages) {
          startPage = 1;
          endPage = visiblePages;
        } else if (currentPage + halfVisiblePages >= totalPages) {
          startPage = totalPages - visiblePages + 1;
          endPage = totalPages;
        } else {
          startPage = currentPage - halfVisiblePages;
          endPage = currentPage + halfVisiblePages;
        }
      }

      const paginationItems = [];
      if (totalPages > 1) {
        paginationItems.push(
          <PaginationItem key="prev" disabled={currentPage === 1}>
            <PaginationLink
              previous
              onClick={() => this.handlePageChange(currentPage - 1)}
              href="#"
            />
          </PaginationItem>
        );

        for (let i = startPage; i <= endPage; i++) {
          paginationItems.push(
            <PaginationItem key={i} active={i === currentPage}>
              <PaginationLink onClick={() => this.handlePageChange(i)} href="#">
                {i}
              </PaginationLink>
            </PaginationItem>
          );
        }

        paginationItems.push(
          <PaginationItem key="next" disabled={currentPage === totalPages}>
            <PaginationLink
              next
              onClick={() => this.handlePageChange(currentPage + 1)}
              href="#"
            />
          </PaginationItem>
        );
      }

      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentItems = dataToPaginate.slice(indexOfFirstItem, indexOfLastItem);

      return (
        <React.Fragment>
          <div>
            <Container fluid>
              <Breadcrumbs
                title={this.props.breadCrumbTitle}
                breadcrumbItem={this.props.breadcrumbItem}
              />
              <Row style={{ marginTop: '0px' }}>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      {/* Search Container */}
                      {this.props.SearchKeyArray &&
                        this.props.SearchKeyArray.length > 0 &&
                        this.props.SearchKeyArray.map((item, index) => (
                          <span
                            key={index}
                            style={{
                              margin: "10px",
                              cursor: "pointer",
                              padding: "5px 10px",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                              display: "inline-block",
                              transition: "transform 0.2s",
                              backgroundColor:
                                this.props.searchKey === item ? "blue" : "white",
                              color:
                                this.props.searchKey === item ? "white" : "black",
                            }}
                            onClick={() => this.props.handleSearchKey(item)}
                          >
                            {item}
                          </span>
                        ))}

                      {this.props.isSearchBox || this.props.isAdd ? (
                        <Row className="mb-2">
                          {this.props.isSearchBox ? (
                            <Col sm="4">
                              <div className="search-box mr-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search..."
                                    onChange={this.props.handleSearchChange}
                                  />
                                  <i className="bx bx-search-alt search-icon"></i>
                                </div>
                              </div>
                            </Col>
                          ) : null}
                          {this.props.isAdd ? (
                            <Col sm="8">
                              <div className="text-sm-right">
                                <Button
                                  type="button"
                                  onClick={this.props.btnAdd_onClick}
                                  color="success"
                                  className="btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                  <i className="mdi mdi-plus mr-1"></i> Add New
                                </Button>
                              </div>
                            </Col>
                          ) : null}
                        </Row>
                      ) : null}

                      {/* Table Data */}
                      <div className="table-responsive">
                        {this.props.isProgress ? (
                          <Progress
                            value={100}
                            color="primary"
                            style={{ width: "100%" }}
                            animated
                          ></Progress>
                        ) : null}
                        {!dataToPaginate || dataToPaginate.length === 0 ? (
                          <div>Data not Found</div>
                        ) : (
                          <Table className="table table-centered table-nowrap">
                            <thead className="thead-light">
                              <tr>
                                {this.props.isCheckBox ? (
                                  <th style={{ width: "20px" }}>
                                    <div className="custom-control custom-checkbox">
                                      <Input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="chkAll"
                                        onChange={(event) => this.props.CheckAllChange(event)}
                                      />
                                      <Label
                                        className="custom-control-label"
                                        htmlFor="chkAll"
                                      >
                                        &nbsp;
                                      </Label>
                                    </div>
                                  </th>
                                ) : null}
                                {this.props.isSNo ? <th>S. No.</th> : null}
                                {this.props.gridHeader()}
                                {this.props.isViewDetails ? (
                                  <th>View Details</th>
                                ) : null}
                                {this.props.isEdit || this.props.isDelete ? (
                                  <th>Action</th>
                                ) : null}
                              </tr>
                            </thead>
                            <tbody>
                              {currentItems
                                ? currentItems.map((formData, key) => (
                                    <tr key={"rawData_" + key}>
                                      {this.props.isCheckBox ? (
                                        <td>
                                          <div className="custom-control custom-checkbox">
                                            <Input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id={formData.id}
                                              checked={this.props.CheckedArray.includes(formData.Id)}
                                              onChange={(event) => this.props.CheckBoxChange(formData.Id, event)}
                                            />
                                            <Label
                                              className="custom-control-label"
                                              htmlFor={formData.id}
                                            >
                                              &nbsp;
                                            </Label>
                                          </div>
                                        </td>
                                      ) : null}
                                      {this.props.isSNo ? (
                                        <td>
                                          {indexOfFirstItem + key + 1}
                                        </td>
                                      ) : null}
                                      {this.props.gridBody(formData)}
                                      {this.props.isViewDetails ? (
                                        <td>
                                          <Button
                                            type="button"
                                            color="primary"
                                            className="btn-sm btn-rounded"
                                            onClick={() =>
                                              this.props.togglemodal(
                                                this.props.obj,
                                                formData
                                              )
                                            }
                                          >
                                            View Details
                                          </Button>
                                        </td>
                                      ) : null}
                                      <td>
                                        <div className="d-flex gap-3">
                                          {this.props.isEdit ? (
                                            <Link
                                              to="#"
                                              value={formData}
                                              className="mr-3 text-primary"
                                              onClick={() =>
                                                this.props.btnEdit_onClick(formData)
                                              }
                                            >
                                              <i
                                                className="mdi mdi-pencil font-size-18 mr-3"
                                                id="edittooltip"
                                              ></i>
                                              <UncontrolledTooltip
                                                placement="top"
                                                target="edittooltip"
                                              >
                                                Edit
                                              </UncontrolledTooltip>
                                            </Link>
                                          ) : null}
                                          {this.props.isDelete ? (
                                            <Link
                                              to="#"
                                              value={formData}
                                              className="text-danger"
                                              onClick={() =>
                                                this.props.toggleDeleteConfirm(
                                                  this.props.obj,
                                                  formData,
                                                  true
                                                )
                                              }
                                            >
                                              <i
                                                className="mdi mdi-close font-size-18 mr-3"
                                                id="deletetooltip"
                                              ></i>
                                              <UncontrolledTooltip
                                                placement="top"
                                                target="deletetooltip"
                                              >
                                                Delete
                                              </UncontrolledTooltip>
                                            </Link>
                                          ) : null}
                                        </div>
                                        {this.props.confirm_alert ? (
                                          <SweetAlert
                                            title="Are you sure?"
                                            warning
                                            showCancel
                                            confirmButtonText="Yes, delete it!"
                                            confirmBtnBsStyle="success"
                                            cancelBtnBsStyle="danger"
                                            onConfirm={() =>
                                              this.props.btnDelete_onClick(
                                                this.props.selectedFormData
                                              )
                                            }
                                            onCancel={() =>
                                              this.props.toggleDeleteConfirm(
                                                this.props.obj,
                                                formData,
                                                false
                                              )
                                            }
                                          >
                                            You won&apos;t be able to revert this!
                                          </SweetAlert>
                                        ) : null}
                                        {this.props.success_dlg ? (
                                          <SweetAlert
                                            success
                                            title={this.props.dynamic_title}
                                            onConfirm={() =>
                                              this.props.toggleDeleteSuccess(
                                                this.props.obj,
                                                false
                                              )
                                            }
                                          >
                                            {this.props.dynamic_description}
                                          </SweetAlert>
                                        ) : null}
                                      </td>
                                    </tr>
                                  ))
                                : null}
                            </tbody>
                          </Table>
                        )}
                      </div>

                      {/* Pagination */}
                      {this.props.isPagination ? (
                        <Pagination className="pagination pagination-rounded justify-content-end mb-2">
                          {paginationItems}
                        </Pagination>
                      ) : null}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>

          <Modal
            isOpen={this.props.isOpenModal}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="exampleModal"
            tabIndex="-1"
            toggle={() => this.props.togglemodal(this.props.obj, null)}
          >
            <div className="modal-content">
              <ModalHeader
                toggle={() => this.props.togglemodal(this.props.obj, null)}
              >
                {this.props.modalTitle}
              </ModalHeader>
              <ModalBody>
                {this.props.modalBody(this.props.selectedFormData)}
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => this.props.togglemodal(this.props.obj, null)}
                >
                  Close
                </Button>
              </ModalFooter>
            </div>
          </Modal>
        </React.Fragment>
      );
    }

    // Return null or a loading message if dataToPaginate is not available
    return <div>Loading...</div>;
  }
}

RCDisplayPage.propTypes = {
  obj: PropTypes.object,
  selectedFormData: PropTypes.object,
  //
  breadCrumbTitle: PropTypes.string,
  breadcrumbItem: PropTypes.string,
  dynamic_title: PropTypes.string,
  dynamic_description: PropTypes.string,
  gridData: PropTypes.array,
  gridHeader: PropTypes.func,
  gridBody: PropTypes.func,
  //
  isOpenModal: PropTypes.bool,
  isSearchBox: PropTypes.bool,
  isAdd: PropTypes.bool,
  isEdit: PropTypes.bool,
  isDelete: PropTypes.bool,
  isCheckBox: PropTypes.bool,
  isSNo: PropTypes.bool,
  isViewDetails: PropTypes.bool,
  isProgress: PropTypes.bool,
  isPagination: PropTypes.bool,
  confirm_alert: PropTypes.bool,
  success_dlg: PropTypes.bool,
  //
  modalTitle: PropTypes.string,
  modalBody: PropTypes.func,
  togglemodal: PropTypes.func,
  toggleDeleteConfirm: PropTypes.func,
  toggleDeleteSuccess: PropTypes.func,
  //
  btnAdd_onClick: PropTypes.func,
  btnEdit_onClick: PropTypes.func,
  btnDelete_onClick: PropTypes.func,
};

export default RCDisplayPage;