import React, { Component } from "react";

//Constants
import { API_WEB_URLS } from "../../constants/constAPI";

//Store
import { Fn_FillListData, Fn_GetReport } from "../../store/functions";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Progress,
  Modal,
} from "reactstrap";

import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import * as XLSX from 'xlsx';

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";


function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
class pageReport_ManualMustorRoll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentData: [],
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      formData: {},
      success_msg: false,
      dynamic_title: "",
      dynamic_description: "",
      company: [],
      page: 1,
      sizePerPage: 10,
      productData: [],
      header_dd: [],
      success_dlg: false,
      rows: [
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
        ["Data"],
      ],
      cols: [],
      F_Type: 0,
      selectedMulti: [],
      OnlineVideo_dd: [],
      user_dd: [],
      F_ChapterMaster: 0,
      F_UserMaster: 0,
      aid: 0,
    };
    this.obj = this;
    this.breadCrumbTitle = "Online Class";
    this.breadCrumbItem = "Manual Mustor Roll";
    this.modalTitle = "Audit Attendance";
    this.rtPage_Add = "add-registration";
    this.rtPage_Edit = "edit-registration";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Registration/Id/0";
    this.API_URL_SAVE = "GetManualMustorRoll/0/token";

    //Event Binding
  
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.exportToExcel  = this.exportToExcel.bind(this);
  }

  componentDidMount() {
    this.setState({YearId : 2, MonthId : 5});
    const obj = JSON.parse(localStorage.getItem("authUser"));
    Fn_FillListData(this.obj, "department", API_WEB_URLS.MASTER + "/0/token/DepartmentMaster/Id/"+obj.uid);
   
  }

  exportToExcel = () => {

  const orderedAndFilteredArray = this.state.productData;
    const worksheet = XLSX.utils.json_to_sheet(orderedAndFilteredArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'ManualMustorRoll');
    XLSX.writeFile(workbook, `ManualMustorRoll.xlsx`);
  };

  
  // modal functions End----------

  btnSave_onClick() {
    this.setState({productData : [{}]})
    const obj = JSON.parse(localStorage.getItem("F_CompanyMaster"));
    let vformData = new FormData();
    vformData.append("F_Month", this.state.MonthId);
    vformData.append("F_YearMaster", this.state.YearId);
    vformData.append("F_CompanyMaster", obj);
    vformData.append("F_DepartmentMaster", this.state.DepID);
   

    Fn_GetReport(
      this.obj,
      { arguList: { id: 0, formData: vformData } },
      this.API_URL_SAVE,
      "productData",
      true
      
    );
  }

  render() {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    let columnNames = this.state.rows[0];

    // Assuming the first row has column names
    const columns = columnNames.map(columnName => ({
      dataField: columnName,
      text: columnName,
      sort: true,
    }));

    const defaultSorted = [
      {
        dataField: "id",
        order: "asc",
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.productData.length, // replace later with size(customers),
      custom: true,
    };

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "15", value: 15 },
      { text: "20", value: 20 },
      { text: "25", value: 25 },
      { text: "All", value: this.state.productData.length },
    ];

    // Select All Button operation
    const selectRow = {
      mode: "checkbox",
    };

    const { SearchBar } = Search;
    const { selectedMulti } = this.state;
    return (
      <>
        <React.Fragment>
          <div className="page-content">
           
            <Container fluid>
              {/* Render Breadcrumb */}
              <Breadcrumbs
                title={this.breadCrumbTitle}
                breadcrumbItem={this.breadCrumbItem}
              />
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                    <Row>

<Col sm="1" className="mb-3">
                        <label htmlFor="firstName" className="col-form-label"> Select Year</label>
                      </Col>
                      <Col sm="2">
                      <select name="YearId"  label="" onChange={(e) => this.setState({YearId : e.target.value})}  value={this.state.YearId} errorMessage="Select YearId " validate={{ required: { value: true } }} type="select" className="form-select" >
                          <option value={-1} defaultValue label={"Select"} />
                          <option value={1} defaultValue label={"2023"} />
                          <option value={2} defaultValue label={"2024"} />
                          <option value={3} defaultValue label={"2025"} />
                        </select>
                      </Col>


                  <Col sm="1" className="mb-3">
                        <label htmlFor="firstName" className="col-form-label"> Select Month</label>
                      </Col>
                      <Col sm="2">
                      <select name="MonthId"  label="" onChange={(e) => this.setState({MonthId : e.target.value})}  value={this.state.MonthId} errorMessage="Select MonthId " validate={{ required: { value: true } }} type="select" className="form-select" >
                      <option value={-1} defaultValue label={"Select"} />
                                            <option value={1} defaultValue label={"Jan"} />
                                            <option value={2} defaultValue label={"Feb"} />
                                            <option value={3} defaultValue label={"Mar"} />
                                            <option value={4} defaultValue label={"Apr"} />
                                            <option value={5} defaultValue label={"May"} />
                                            <option value={6} defaultValue label={"Jun"} />
                                            <option value={7} defaultValue label={"Jul"} />
                                            <option value={8} defaultValue label={"Aug"} />
                                            <option value={9} defaultValue label={"Sep"} />
                                            <option value={10} defaultValue label={"Oct"} />
                                            <option value={11} defaultValue label={"Nov"} />
                                            <option value={12} defaultValue label={"Dec"} />
                        </select>
                      </Col>
                      <Col sm="1" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label"> Select Dep</label>
                                        </Col>
                                        <Col sm="2">
                                        <select name="DepID"  label="" onChange={(e) => this.setState({DepID : e.target.value})}  value={this.state.DepID} errorMessage="Select DepID " validate={{ required: { value: true } }} type="select" className="form-select" >
                                        <option value={-1} defaultValue label={"Select"} />
                                            {this.state.department
                                              ? this.state.department.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </select>
                                        </Col>
                      <Col sm="3">
                      <Button
        type="button"
        onClick={this.btnSave_onClick}
        color="primary"
        className="mr-1 waves-effect waves-light"
      >
        View
      </Button>

      <></>

      <Button
        type="button"
        onClick={this.exportToExcel}
        color="primary"
        className="mr-1 waves-effect waves-light"
      >
        Excel
      </Button>
                      </Col>


                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                            
                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                     // selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            </Container>
          </div>
        </React.Fragment>
      </>
    );
  }
}
export default compose(container)(pageReport_ManualMustorRoll);
