import { API_WEB_URLS } from "constants/constAPI";
import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import {
  Fn_FillListData,
  Fn_AddEditData,
  Fn_GetReport,
  Fn_Excel,
} from "store/functions";
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import SweetAlert from "react-bootstrap-sweetalert";

class ChangeAtt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      selectedCell: { row: -1, col: -1 },
    };

    this.save = this.save.bind(this);
    this.APIURL = "GetMonthlyAtt/0/token";
    this.obj = this;
    this.API_URL_SAVE = "InvoiceTax/0/token";
    this.monthChange = this.monthChange.bind(this);
    this.syno = this.syno.bind(this);
    this.excel = this.excel.bind(this);
  }

  componentDidMount() {
    this.setState({ YearId: 2, MonthId: 5 });
    const obj = JSON.parse(localStorage.getItem("authUser"));
    Fn_FillListData(
      this.obj,
      "department",
      API_WEB_URLS.MASTER + "/0/token/DepartmentMaster/Id/" + obj.uid
    );
    document.addEventListener("keydown", this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
  }

  handleCellClick = (rowIndex, colIndex) => {
    this.setState({ selectedCell: { row: rowIndex, col: colIndex } });
  };
  handleKeyPress = event => {
    const { key } = event;
    const { tableData, selectedCell } = this.state;
    const { row, col } = selectedCell;
    const maxCols =
      Object.keys(tableData[0]).filter(key => key.startsWith("D")).length + 2;

    if (
      row !== -1 &&
      col !== -1 &&
      (key == "1" || key == "2" || key == "3" || key == "4" || key == "5")
    ) {
      // const newValue = key === "1" ? "P" : "A";
      const newValue = key;
      const newData = [...tableData];
      newData[row][`D${col - 1}`] = newValue;
      this.setState({ tableData: newData });
    } else if (key === "Enter") {
      this.setState(prevState => {
        let newCol = prevState.selectedCell.col + 1;
        let newRow = prevState.selectedCell.row;

        if (newCol === maxCols) {
          newCol = 2;
          newRow++;
        }

        return {
          selectedCell: { row: newRow, col: newCol },
        };
      });
    }
  };

  syno() {
    this.setState({ success_msg: false });
  }

  save() {
    let vformData2 = new FormData();

    vformData2.append("F_Month", this.state.MonthId);
    vformData2.append("F_YearMaster", this.state.YearId);
    vformData2.append("Data", JSON.stringify(this.state.tableData));

    Fn_AddEditData(
      this.obj,
      { arguList: { id: 0, formData: vformData2 } },
      this.API_URL_SAVE,
      "#",
      true
    );
  }

  excel() {
    const obj = JSON.parse(localStorage.getItem("F_CompanyMaster"));
    let vformData = new FormData();
    vformData.append("F_Month", this.state.MonthId);
    vformData.append("F_YearMaster", this.state.YearId);
    vformData.append("F_CompanyMaster", obj);
    vformData.append("F_DepartmentMaster", this.state.DepID);
    Fn_Excel(
      this.obj,
      { arguList: { id: 0, formData: vformData } },
      "GetManualMustorRoll/0/token",
      "productData",
      true
    );
  }

  monthChange() {
    const obj = JSON.parse(localStorage.getItem("F_CompanyMaster"));
    let vformData = new FormData();
    vformData.append("F_Month", this.state.MonthId);
    vformData.append("F_YearMaster", this.state.YearId);
    vformData.append("F_CompanyMaster", obj);
    vformData.append("F_DepartmentMaster", this.state.DepID);

    Fn_GetReport(
      this.obj,
      { arguList: { id: 0, formData: vformData } },
      this.APIURL,
      "tableData",
      true
    );
  }

  render() {
    const { tableData } = this.state;

    const headers = Array.from({ length: 31 }, (_, index) => (
      <th key={index}>D{index + 1}</th>
    ));

    const rows = tableData.map((rowData, rowIndex) => {
      
      const PD = Object.values(rowData).filter(value => value == 1).length;
      const WH = Object.values(rowData).filter(value => value == 3).length;
      const FH = Object.values(rowData).filter(value => value == 4).length;
      const L = Object.values(rowData).filter(value => value == 5).length;
      const A = Object.values(rowData).filter(value => value == 2).length;
      const MD = new Date(2024, Number(this.state.MonthId), 0).getDate();


    return(
      <tr key={rowIndex}>
        <td>{rowData.Name}</td>
        {Object.keys(rowData).map((key, colIndex) => {
          if (
            key !== "Id" &&
            key !== "Name" &&
            key !== "MD" &&
            key !== "WD" &&
            key !== "PD" &&
            key !== "LD" &&
            key !== "DateOfCreation" &&
            key !== "LastUpdateOn" &&
            key !== "F_EmployeeMaster" &&
            key !== "F_Month"
          ) {
            const cellValue =
              rowData[key] == 1
                ? "P"
                : rowData[key] == "2"
                ? "A"
                : rowData[key] == "3"
                ? "WH"
                : rowData[key] == "4"
                ? "FH"
                : rowData[key] == "5"
                ? "L"
                : "A";
            return (
              <td
                key={colIndex}
                onClick={() => this.handleCellClick(rowIndex, colIndex)}
                style={{
                  backgroundColor:
                    rowIndex === this.state.selectedCell.row &&
                    colIndex === this.state.selectedCell.col
                      ? "yellow"
                      : "white",
                }}
              >
                {cellValue}
                
              </td>
            );
          }
          return null;
        })}
        
         <td>{PD}</td>
         <td>{WH}</td>
         <td>{FH}</td>
         <td>{L}</td>
         <td>{A}</td>
         <td>{MD}</td>
      </tr>
    )
  });

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm="1" className="mb-3">
                        <label htmlFor="firstName" className="col-form-label">
                          {" "}
                          Select Year
                        </label>
                      </Col>
                      <Col sm="2">
                        <select
                          name="YearId"
                          label=""
                          onChange={e =>
                            this.setState({ YearId: e.target.value })
                          }
                          value={this.state.YearId}
                          errorMessage="Select YearId "
                          validate={{ required: { value: true } }}
                          type="select"
                          className="form-select"
                        >
                          <option value={-1} defaultValue label={"Select"} />
                          <option value={1} defaultValue label={"2023"} />
                          <option value={2} defaultValue label={"2024"} />
                          <option value={3} defaultValue label={"2025"} />
                        </select>
                      </Col>

                      <Col sm="1" className="mb-3">
                        <label htmlFor="firstName" className="col-form-label">
                          {" "}
                          Select Month
                        </label>
                      </Col>
                      <Col sm="2">
                        <select
                          name="MonthId"
                          label=""
                          onChange={e =>
                            this.setState({ MonthId: e.target.value })
                          }
                          value={this.state.MonthId}
                          errorMessage="Select MonthId "
                          validate={{ required: { value: true } }}
                          type="select"
                          className="form-select"
                        >
                          <option value={-1} defaultValue label={"Select"} />
                          <option value={1} defaultValue label={"Jan"} />
                          <option value={2} defaultValue label={"Feb"} />
                          <option value={3} defaultValue label={"Mar"} />
                          <option value={4} defaultValue label={"Apr"} />
                          <option value={5} defaultValue label={"May"} />
                          <option value={6} defaultValue label={"Jun"} />
                          <option value={7} defaultValue label={"Jul"} />
                          <option value={8} defaultValue label={"Aug"} />
                          <option value={9} defaultValue label={"Sep"} />
                          <option value={10} defaultValue label={"Oct"} />
                          <option value={11} defaultValue label={"Nov"} />
                          <option value={12} defaultValue label={"Dec"} />
                        </select>
                      </Col>

                      <Col sm="1" className="mb-3">
                        <label htmlFor="firstName" className="col-form-label">
                          {" "}
                          Select Dep
                        </label>
                      </Col>
                      <Col sm="2">
                        <select
                          name="DepID"
                          label=""
                          onChange={e =>
                            this.setState({ DepID: e.target.value })
                          }
                          value={this.state.DepID}
                          errorMessage="Select DepID "
                          validate={{ required: { value: true } }}
                          type="select"
                          className="form-select"
                        >
                          <option value={-1} defaultValue label={"Select"} />
                          {this.state.department
                            ? this.state.department.map((option, key) => (
                                <option
                                  key={option.Id}
                                  value={option.Id}
                                  label={option.Name}
                                />
                              ))
                            : null}
                        </select>
                      </Col>

                      <Col sm="3">
                        <Button
                          type="button"
                          onClick={this.monthChange}
                          color="primary"
                          className="mr-1 waves-effect waves-light"
                        >
                          View
                        </Button>
                      </Col>
                    </Row>
                    <h5>1 - (P) , 2 - (A), 3 - (WH) , 4 - (FH) , 5 - (L)</h5>
                    <table
                      style={{ borderCollapse: "collapse", width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              backgroundColor: "#f2f2f2",
                              padding: "8px",
                              textAlign: "left",
                              border: "1px solid #ddd",
                            }}
                          >
                            Name
                          </th>
                          {headers}
                          <td  style={{
                              backgroundColor: "#f2f2f2",
                              padding: "8px",
                              textAlign: "left",
                              border: "1px solid #ddd",
                            }}>PD</td>
         <td  style={{
                              backgroundColor: "#f2f2f2",
                              padding: "8px",
                              textAlign: "left",
                              border: "1px solid #ddd",
                            }}>WH</td>
         <td  style={{
                              backgroundColor: "#f2f2f2",
                              padding: "8px",
                              textAlign: "left",
                              border: "1px solid #ddd",
                            }}>FH</td>
         <td  style={{
                              backgroundColor: "#f2f2f2",
                              padding: "8px",
                              textAlign: "left",
                              border: "1px solid #ddd",
                            }}>L</td>
         <td  style={{
                              backgroundColor: "#f2f2f2",
                              padding: "8px",
                              textAlign: "left",
                              border: "1px solid #ddd",
                            }}>A</td>
         <td  style={{
                              backgroundColor: "#f2f2f2",
                              padding: "8px",
                              textAlign: "left",
                              border: "1px solid #ddd",
                            }}>MD</td>
                        </tr>
                      </thead>
                      <tbody>{rows}</tbody>
                    </table>

                    <div className="d-flex flex-wrap gap-2">
                      <Button
                        type="button"
                        onClick={this.save}
                        color="primary"
                        className="mr-1 waves-effect waves-light"
                      >
                        Save
                      </Button>
                    </div>

                    {this.state.success_msg ? (
                      <SweetAlert
                        title="Attendance Saved Successfully!"
                        success
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnText="Excel"
                        cancelBtnBsStyle="success"
                        onConfirm={this.syno}
                        onCancel={this.excel}
                      >
                        You clicked the button!
                      </SweetAlert>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(container)(ChangeAtt);
