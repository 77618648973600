import React ,{useState , useRef} from "react";
import TableRowsPly from "./TableRowsPly";
function AddDeleteTableRowsPly({  data , add , edit , Employee,Shift, del}){



    const [rowsData, setRowsData] = useState([]);
  
  
   const deleteTableRows = (index)=>{
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
   }


   
 
    return(
        <div className="table-responsive">
                <table className="" style={{"padding": "0" , "marginBottom" : "10px"}}>
                    <thead>
                      <tr>
                      <th>Employee</th>
                            <th>Shift 1</th>  
                            <th>Shift 2</th>  
                            <th> <button   type="button" className="btn btn-outline-success" onClick={add} >+</button></th>    
                      </tr>
                    </thead>
                    <tbody>
                   <TableRowsPly rowsData={data}   add ={add} Employee={Employee} Shift={Shift} edit={edit} del={del}/>
                   </tbody> 
                </table>
              
        </div>
    )
}
export default AddDeleteTableRowsPly