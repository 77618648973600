import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store


import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_GetReport,
  Fn_AddEditData,
  Fn_ChangeStateValue
} from "../../store/functions";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal
} from "reactstrap";

import {
  AvForm,
  AvField,AvRadioGroup,AvRadio
} from "availity-reactstrap-validation";

import { compose } from "recompose";
import PropTypes from 'prop-types'
import { container } from "../../store/Containers/cntCommon";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react"

import { initializeApp } from "firebase/app";
import { getDatabase, ref, get } from "firebase/database";


  
const firebaseConfig = {
  apiKey: "AIzaSyB4xxmODUWwphCmkSnM0R56xUzn5EM6ZhQ",
  authDomain: "vc-app-9beac.firebaseapp.com",
  databaseURL: "https://vc-app-9beac-default-rtdb.firebaseio.com",
  projectId: "vc-app-9beac",
  storageBucket: "vc-app-9beac.appspot.com",
  messagingSenderId: "563535596504",
  appId: "1:563535596504:web:ff209154658c12ed64c0ad",
  measurementId: "G-1V6MJ7DQ1S"
};

const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);


const LoadingContainer = () => <div>Loading...</div>



class pageList_Holiday extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      formData : {},
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      company : [],
      InTimeN : '00:00',
      OutTimeN : '00:00',
      modal_center : false
    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Holidays";
    this.modalTitle = "Holidays";
    this.rtPage_Add = "add-holiday";
    this.rtPage_Edit = "edit-holiday";
    this.API_URL = "GetAppAtt/0/token";
    this.API_URL_COMPANY = "GetHolidays/0/token";

    //Event Binding
    this.btnAdd_onClick = this.btnAdd_onClick.bind(this);
    this.btnEdit_onClick = this.btnEdit_onClick.bind(this);
    this.btnDelete_onClick = this.btnDelete_onClick.bind(this);
    this.btnSave_onClick =  this.btnSave_onClick.bind(this);
    this.viewimg  = this.viewimg.bind(this);
   
   

  }





  componentDidMount() {
    const { id } = this.props.match.params;
    const obj = JSON.parse(localStorage.getItem("authUser"));
   
  }

 

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  btnSave_onClick (event, formData)  {

    let vformData = new FormData();
    vformData.append("FromDate", formData.FromDate);
    vformData.append("ToDate", formData.ToDate);
    
    Fn_GetReport(this.obj, { arguList: { id: 0, formData: vformData } }, this.API_URL_COMPANY, "gridData", true);
   // Fn_FillListData(this.obj, "gridData",  this.API_URL_COMPANY);


  }


 


  btnAdd_onClick(event, values) {
    this.props.history.push(`${this.rtPage_Add}`);
  }
  btnEdit_onClick(formData) {
    this.props.history.push(`${this.rtPage_Edit}/${formData.Id}`, {});
  }
  btnDelete_onClick(formData) {
    Fn_DeleteData(this.obj, formData.Id, this.API_URL, this.API_URL + "/Id/0");
  }
  renderGridHeader() {
    return (
      <>
        <th>Name </th>
        <th>From </th>
        <th>To</th>
     
      </>
    );
  }

viewimg () {

}

  renderGridBody(formData) {
    return (
      <>
        <td>{formData.Name}</td>
        <td>{formData.From}</td>
        <td>{formData.To}</td>
       

       
      </>
    );
  }


 

  renderModalBody(selectedFormData) {

    
    return (
      <>
          


        
      </>
    );
  }
  render() {
    return (




     <>

<React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                   
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                            
                            <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                         
                                <Row>
                                  <Col lg="9">
                                  {/* <Card>
                                    <CardBody> */}
                                    <Row>
                                 

                                        <Col sm="2" className="mb-3">
                                          <label htmlFor="DateofBirth" className="col-form-label">From Date</label>
                                        </Col>
                                        <Col sm="3" className="mb-3">
                                        <AvField name="FromDate" label=""  onChange={(e) => Fn_ChangeStateValue(this.obj, 'FromDate', e.target.value)} value={this.state.formData.FromDate} placeholder="From Date" errorMessage="Select Date " type="date"  className="form-control" />
                                        </Col>


                                        <Col sm="2" className="mb-3">
                                          <label htmlFor="DateofBirth" className="col-form-label">To Date</label>
                                        </Col>
                                        <Col sm="3" className="mb-3">
                                        <AvField name="ToDate" label=""  onChange={(e) => Fn_ChangeStateValue(this.obj, 'ToDate', e.target.value)} value={this.state.formData.ToDate} placeholder="To Date" errorMessage="Select Date "  type="date"  className="form-control" />
                                        </Col>

                                        <Col sm="3" className="mb-3">
                                        <Button
                          type="submit"
                          color="primary"
                        
                          className="mr-1 waves-effect waves-light"
                        >
                          View
                        </Button>
                                          </Col>
                                      
                                    
                                      </Row>

                                     
                                   

                       
                                      
                         {/* </CardBody>
                         </Card> */}
                         </Col>
                         </Row>
                        

                         
                         </AvForm>

                         </div> 



                         </div>

                        
                       
                          </CardBody>
                         </Card>


                         
                         </Col>
                         </Row>



                         
                         </Container>
                         </div>
                         </React.Fragment>
                       


      <RCDisplayPage
        //page header paramaters
        Isbreadcrumb = {false}
        breadCrumbTitle={this.breadCrumbTitle}
        breadcrumbItem={this.breadCrumbItem}
        obj={this.obj}
        //column paramaters
        isSearchBox={false}
        isSNo={true}
        isCheckBox={true}
        isViewDetails={false}
        InTimeN = {this.state.InTimeN}
        OutTimeN={this.state.OutTimeN}
        //grid paramaters
        gridData={this.state.gridData}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        btnDelete_onClick={this.btnDelete_onClick}
        removeBodyCss={this.removeBodyCss}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        updateatt={this.updateatt}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        togglemodal2={togglemodal}
        tog_center={this.tog_center}
      
        edit={this.edit}
        //user rights
        isAdd={true}
        isEdit2={true}
        isDelete={false}
      ></RCDisplayPage>
  </>
    );
  }
}





pageList_Holiday.propTypes = {
  google: PropTypes.object
}

export default compose(container)(
  GoogleApiWrapper({
    apiKey: "AIzaSyBZmCAe7jFv7GKMxqwCV-VXa8XuwrlB8EE",
    LoadingContainer: LoadingContainer,
    v: "3",
  })(pageList_Holiday)
)
