import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Progress
} from "reactstrap";

import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert"


// availity-reactstrap-validation
import {
  AvForm,
  AvField,AvRadioGroup,AvRadio
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withRouter, Link } from "react-router-dom";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData } from "../../store/functions";

class pageAddEdit_ImportFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      company : [],
      Data : [],
      bar : 1,
      barNo : 0,
      F_CompanyMaster  :  0,
      MachineId : 0,
      success_msg  : false,
      Loader : false
    };
    this.obj = this;
    this.formTitle = "Import File";
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem =   this.formTitle;
    this.API_URL_SAVE = API_WEB_URLS.RAWDATA + "/0/token";
    this.pushFormName = "/masters-customermaster";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);

    this.btnSave_onClick2 = this.btnSave_onClick2.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.syno =  this.syno.bind(this);
    this.test =  this.test.bind(this);
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/CustomerMaster";

    this.API_URL_COMPANY = API_WEB_URLS.MASTER + "/0/token/CompanyMaster";
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    const obj = JSON.parse(localStorage.getItem("authUser"));
    Fn_FillListData(this.obj, "company", this.API_URL_COMPANY + "/F_CustomerMaster/"+obj.uid);
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }
  btnSave_onClick(event, formData) {
    if (!this.state.id)
      Fn_AddEditData(
        this.obj,
        { arguList: { id: 0, name: formData.Name , userName : formData.UserName , userPassword : formData.UserPassword , isAuditAtt : formData.IsAuditAtt } },
        this.API_URL_SAVE,
        this.pushFormName
      );
    else
      Fn_AddEditData(
        this.obj,
        { arguList: { id: this.state.id, name: formData.Name , userName : formData.UserName , userPassword : formData.UserPassword , isAuditAtt : formData.IsAuditAtt}
      
      },
        this.API_URL_SAVE,
        this.pushFormName
      );
  }


  syno () {

    this.setState({
      success_msg : false
    });
      

      };


  

  

  async test(i, lines , so ) {


    
    const obj = JSON.parse(localStorage.getItem("authUser"));

    var F_CustomerMaster   =  obj.uid;
    var Machine =  this.state.MachineId;
    var F_CompanyMaster  =  localStorage.getItem("F_CompanyMaster");

    var bar  =  i/ lines.length * 100;
      
    this.setState({ bar: bar , barNo : i + '/' + lines.length });


   


 

  so  =  so + 1;

    if (so == 200) {

    so = 1;    

      setTimeout(() => {
   
                    
    if (i  <= lines.length) { 


      var nameofuser =  lines[i];

      
    if (nameofuser  == undefined){

      this.setState({
        Loader : true
      });

      let vformData = new FormData();
      vformData.append("F_CompanyMaster", localStorage.getItem("F_CompanyMaster"));


   var url  =  "http://apiatt.shinewellinnovation.com/api/V1/InsertAttendance/0/token";

   const requestOptions = {
    method: 'POST',
    body: vformData,
  };
      return fetch(url, requestOptions)
        .then((response) => response.json())
        .then((json) => {
          if(json.data.response[0].Name  ==  'Success'){
            this.setState({
              success_msg2 : true,
              Loader : false
            });
}

else {
  alert('some error ocurred');
}
        })
        .catch((error) => {
          console.error(error);
        });



    }

      else {

     

      var nameofuser =  lines[i];
      var values = nameofuser.trim().split(/\s+/);
      



      if (Machine  == 'A')
      {

      

      if (values.length  == 11) {
        var SerialNo  =  values[0];
        var EmployeeCode =  values[2];
       
         var CheckDate  =  values[9];
        var CheckTime =  values[10];

      }

      else if (values.length  == 10) {
        var SerialNo  =  values[0];
        var EmployeeCode =  values[2];
       
         var CheckDate  =  values[8];
        var CheckTime =  values[9];
       
      }


    else if (values.length  == 12) {
        var SerialNo  =  values[0];
        var EmployeeCode =  values[2];
       
         var CheckDate  =  values[10];
        var CheckTime =  values[11];

      }


      
    else if (values.length  == 13) {
      var SerialNo  =  values[0];
      var EmployeeCode =  values[2];
     
       var CheckDate  =  values[11];
      var CheckTime =  values[12];
     
    }

  }



 else if (Machine  ==  'B'){
var SerialNo  =  i;
var EmployeeCode  =  values[0];
var CheckDate  =  values[1];
var CheckTime =  values[2];
  }



  // console.log(SerialNo);
  // console.log(EmployeeCode);
  // console.log(CheckDate);
  // console.log(CheckTime);



 Fn_AddEditData(
      this.obj,
      { arguList: { id: 0, F_CompanyMaster: localStorage.getItem("F_CompanyMaster") , F_CustomerMaster : F_CustomerMaster , EmployeeCode : EmployeeCode , SerialNo : SerialNo , CheckTime : CheckTime, CheckDate : CheckDate } },
      this.API_URL_SAVE,
      "#"
    )

    i++;  

      this.test(i , lines , so);


 }
    }  
  }, 2000);


}


else {

  
  if (i  <= lines.length) { 

    var nameofuser =  lines[i];

    console.log(nameofuser);

    if (nameofuser  == undefined){

      this.setState({
        Loader : true
      });

      let vformData = new FormData();
      vformData.append("F_CompanyMaster", localStorage.getItem("F_CompanyMaster"));


   var url  =  API_WEB_URLS.BASE+"InsertAttendance/0/token";

   const requestOptions = {
    method: 'POST',
    body: vformData,
  };
      return fetch(url, requestOptions)
        .then((response) => response.json())
        .then((json) => {
          if(json.data.response[0].Name  ==  'Success'){
            this.setState({
              success_msg : true,
              Loader : false
            });
}
        })
        .catch((error) => {
          console.error(error);
        });



    }

 


    else {
    
        var nameofuser2 =  lines[i];
      var values = nameofuser2.trim().split(/\s+/);
      




      if (Machine ==  'A') {

      
      if (values.length  == 11) {
        var SerialNo  =  values[0];
        var EmployeeCode =  values[2];
       
         var CheckDate  =  values[9];
        var CheckTime =  values[10];

      }


      else if (values.length  == 10) {
        var SerialNo  =  values[0];
        var EmployeeCode =  values[2];
      
         var CheckDate  =  values[8];
        var CheckTime =  values[9];
       
      }

    else if (values.length  == 12) {
        var SerialNo  =  values[0];
        var EmployeeCode =  values[2];
        
         var CheckDate  =  values[10];
        var CheckTime =  values[11];

      }


      
    else if (values.length  == 13) {
      var SerialNo  =  values[0];
      var EmployeeCode =  values[2];
    
       var CheckDate  =  values[11];
      var CheckTime =  values[12];
     
    }


  }
else if (Machine  ==  'B'){
  var SerialNo  =  i;
  var EmployeeCode  =  values[0];
  var CheckDate  =  values[1];
  var CheckTime =  values[2];
    }

    else if (Machine ==  'C'){
      var SerialNo  =  i;
      var EmployeeCode =  values[0];
     
       var CheckDate  =  values[1];
      var CheckTime =  values[2];
    }
  
  
  
    // console.log(SerialNo);
    // console.log(EmployeeCode);
    // console.log(CheckDate);
    // console.log(CheckTime);




  
 Fn_AddEditData(
      this.obj,
      { arguList: { id: 0, F_CompanyMaster: localStorage.getItem("F_CompanyMaster") , F_CustomerMaster : F_CustomerMaster , EmployeeCode : EmployeeCode , SerialNo : SerialNo , CheckTime : CheckTime, CheckDate : CheckDate } },
      this.API_URL_SAVE,
      "#"
    )


    i++;                    //  increment the counter


    this.test(i , lines , so);

 }
  }  



}



   



  
  }


  async btnSave_onClick2 ()  {

     
 if (localStorage.getItem("F_CompanyMaster") == null){
  alert("Please Select Company first from Dashboard");
 }

 else {
    
    
        
          var lines  =  this.state.Data;

          this.test(1 , lines ,1);
 }


}



  showFile = async (e) => {
   
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => { 

      var lines = e.target.result.trim().split('\n');
      
      this.setState({ Data: lines });

      var line  =  lines[99];

      var values = line.trim().split(/\s+/);


     // alert(text)
    };
    reader.readAsText(e.target.files[0])
  }
 



  SetCompany = (event)=> {

 
      this.setState({ F_CompanyMaster: event.target.value });
    
  }

  SetMachineType  =  (event)  => {


    this.setState({ MachineId: event.target.value });

  }
  



  btnCancel_onClick = event => {
    event.preventDefault();
    //this.props.history.goBack();
    this.props.history.push(this.pushFormName);
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
                    <div className="wizard clearfix">
                     
                      <div className="content clearfix">
                        <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                         
                                <Row>
                                  <Col lg="6">
                                  <Card>
                                    <CardBody>
                                    {/* <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">Select Company</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="CompanyId"  onChange={this.SetCompany} label=""  value={this.state.formData.CompanyId === null ? '-1'   : this.state.formData.CompanyId} errorMessage="Select Company " validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.company
                                              ? this.state.company.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
                                          </AvField>
                                        </Col>
                                      </Row> */}


                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">Select Machine</label>
                                        </Col>
                                        <Col sm="6">
                                        <AvField name="MachineId"  onChange={this.SetMachineType} label=""   errorMessage="Select Machine " validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                        
                                                    <option key="A" value="A" label="Machine A" />

                                                    <option key="B" value="B" label="Machine B (Kalinga)" />

                                                    <option key="C" value="C" label="Machine C (Dat)" />
                                                  
                                          </AvField>
                                        </Col>
                                      </Row>


                                      <Row>
                                        <Col sm="4" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">File</label>
                                        </Col>
                                        <Col sm="6">
                                          <AvField name="file" label="" onChange={(e) => this.showFile(e)} value={this.state.formData.Name === null ? ''   : this.state.formData.Name} placeholder="Enter Customer Name" errorMessage="Enter Customer Name" validate={{ required: { value: true } }} type="file" className="form-control" />
                                        </Col>
                                      </Row>


                                      <div className="">
                      <Progress color="primary" value={this.state.bar}>
                        {this.state.barNo}
                      </Progress>
                    </div>


                    {this.state.Loader ? (
                      <>
                    <div
                        className="spinner-border text-info m-1"
                        role="status"
                      >
                      
                        <span className="sr-only">Loading...</span>
                      </div>
                      <div>Wait a min...</div>
                      </>
  ) : null}


                                    </CardBody>
                                  </Card>
                                  </Col>
                                 
                                </Row>
                                <Row>
                                </Row>
                         
                           
                                <div className="d-flex flex-wrap gap-2">
                          <Button
                          type="button"
                          color="primary"
                          onClick={this.btnSave_onClick2}
                          className="mr-1 waves-effect waves-light"
                        >
                          Proceed
                        </Button>

                        <Button
                          type="button"
                          color="secondary"
                          className="waves-effect"
                          onClick={this.btnCancel_onClick}
                        >
                          Cancel
                        </Button>


                        {this.state.success_msg2 ? (
                      <SweetAlert
                        title="Data Imported Successfully!"
                        success
                       
                        confirmBtnBsStyle="success"
                      
                        onConfirm={this.syno}
                        
                      >
                                              </SweetAlert>
                    ) : null}
                          </div>
                        </AvForm>
                      </div>
                      
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(container)(pageAddEdit_ImportFile);
