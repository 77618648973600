import React ,{useState , useRef} from "react";
import TableRowsAtt from "./TableRowsAtt";
function AddDeleteTableAtt({  data  , edit}){



    const [rowsData, setRowsData] = useState([]);
  
  
   const deleteTableRows = (index)=>{
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
   }


   
 
    return(
        <div className="table-responsive">
                <table className="" style={{"padding": "0" , "marginBottom" : "10px"}}>
                    <thead>
                      <tr>
                      <th>Employee</th>
                      <th>Father Name</th>
                      <th>Department</th>  
                      <th>WH</th>  
                            <th>WorkingDays</th>  
                            <th>Leaves</th>  
                            <th>EmpId</th>  
                            <th>IsDone?</th>
                            {/* <th> <button   type="button" className="btn btn-outline-success" onClick={add} >+</button></th>     */}
                      </tr>
                    </thead>
                    <tbody>
                   <TableRowsAtt rowsData={data}  edit={edit} />
                   </tbody> 
                </table>
              
        </div>
    )
}
export default AddDeleteTableAtt