import React  , {useRef} from "react";


function TableRowsPly({rowsData ,add , Employee , Shift, edit, del}) {

  
  const Employees = useRef([]);
  const Shift1s = useRef([]);
  const Shift2s = useRef([]);
  const adds = useRef([]);
  const rems = useRef([]);


  const handleFocus = (event) => event.target.select();

  const handler2 = (index ,evnt , indo) => {
  
     

      const rowsInput = [...rowsData];   
      
      if (evnt.key == 'Enter') {
   
        if (index == 'Shift1')
        Shift1s.current[indo].focus();
       else if (index == 'Shift2')
       Shift2s.current[indo].focus();
       else if (index == 'Adds')
       adds.current[indo].focus();
       else if (index == 'Employee'){
       Employees.current[indo].focus();
       }
       else if (index == 'rems'){
        rems.current[indo].focus();
        }
        
        

evnt.preventDefault();
      }

};

    return(
        
        rowsData.map((data, index)=>{
            const {Id ,F_EmployeeMaster , F_ShiftMaster1 , F_ShiftMaster2}= data;
            return(
                <tr key={index}>

<td> <select ref={(el) => (Employees.current[index] = el)}   style={{"width" : "200px"}}  onChange={(evnt)=>(edit(index, evnt))} onKeyPress={(evnt)=>(handler2('Shift1' ,evnt , index))}  value={F_EmployeeMaster} name="F_EmployeeMaster"   className="form-select">
<option value={0} defaultValue label={"Employee..."} />
                                            {Employee
                                              ? Employee.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  )
                                                )
                                              : null}
              </select>
              </td>


             
            

              <td> <select ref={(el) => (Shift1s.current[index] = el)}   style={{"width" : "200px"}}  onChange={(evnt)=>(edit(index, evnt))} onKeyPress={(evnt)=>(handler2('Shift2' ,evnt , index))}  value={F_ShiftMaster1} name="F_ShiftMaster1"   className="form-select">
<option value={0} defaultValue label={"Shift 1..."} />
                                            {Shift
                                              ? Shift.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.AName} />
                                                  )
                                                )
                                              : null}
              </select>
              </td>

                            

              <td> <select ref={(el) => (Shift2s.current[index] = el)}   style={{"width" : "200px"}}  onChange={(evnt)=>(edit(index, evnt))} onKeyPress={(evnt)=>(handler2('Adds' ,evnt , index))}  value={F_ShiftMaster2} name="F_ShiftMaster2"   className="form-select">
<option value={0} defaultValue label={"Shift 2..."} />
                                            {Shift
                                              ? Shift.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.AName} />
                                                  )
                                                )
                                              : null}
              </select>
              </td>
   

              <td>
                  <button ref={(el) => (adds.current[index] = el)} onKeyPress={(evnt)=>(handler2('rems' ,evnt , index))}  type="button" className="btn btn-outline-success" onClick={add} >+</button>
                  </td>  
                  <td>
                  <button ref={(el) => (rems.current[index] = el)} onKeyPress={(evnt)=>(handler2('Employee' ,evnt , index+1))} className="btn btn-outline-danger" type="button" onClick={()=>(del(Id, index))}>x</button>
                  </td>  
            </tr>
            )
        })
   
    )
    
}
export default TableRowsPly;