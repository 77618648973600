export const API_WEB_URLS = {
  //BASE: "http://localhost:4554/api/V1/",
 //BASE: "http://apivartikaindian.shinewellinnovation.com/api/V1/",
 BASE: "http://apicrosscountry.shinewellinnovation.com/api/V1/",
  //IMAGEURL : "http://apiatt.shinewellinnovation.com/MemberImages/",
  IMAGEURL : "http://localhost:4554/MemberImages/",

  LOGIN: "Authentication/Login",
  //
  MASTER: "Masters",


  CUSTOMER: "CustomerMaster",
  EmployeeMaster: "EmployeeMaster",
  EMPDEPARTMENTL: "InsertEmpDepartmentL",
  EMPBASIC: "InsertEmpBasic",
  COMPANY : "CompanyMaster",
  SHIFT : "ShiftMaster",
  RAWDATA : "InsertRawData",
  GLOBALOPTIONS : "UpdateGlobalOptions"

};
